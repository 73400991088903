@keyframes ldio-f8yo9nicvx7-o {
    0%    { opacity: 1; transform: translate(0 0) }
    49.99% { opacity: 1; transform: translate(40px,0) }
    50%    { opacity: 0; transform: translate(40px,0) }
    100%    { opacity: 0; transform: translate(0,0) }
}
@keyframes ldio-f8yo9nicvx7 {
    0% { transform: translate(0,0) }
    50% { transform: translate(40px,0) }
    100% { transform: translate(0,0) }
}
.ldio-f8yo9nicvx7 div {
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    top: 30px;
    left: 10px;
}
.ldio-f8yo9nicvx7 div:nth-child(1) {
    background: #22f396;
    animation: ldio-f8yo9nicvx7 1s linear infinite;
    animation-delay: -0.5s;
}
.ldio-f8yo9nicvx7 div:nth-child(2) {
    background: #689cc5;
    animation: ldio-f8yo9nicvx7 1s linear infinite;
    animation-delay: 0s;
}
.ldio-f8yo9nicvx7 div:nth-child(3) {
    background: #22f396;
    animation: ldio-f8yo9nicvx7-o 1s linear infinite;
    animation-delay: -0.5s;
}
.loadingio-spinner-dual-ball-ehnduvxrdtd {
    width: 50px;
    height: 50px;
    display: block;
    overflow: hidden;
    background: none;
    position: absolute;
    left: 5px;
    top: 30px;
}
.ldio-f8yo9nicvx7 {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(0.5);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
}
.ldio-f8yo9nicvx7 div { box-sizing: content-box; }