@import url(https://fonts.googleapis.com/css2?family=Yaldevi:wght@400;500;600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Todobody_Todobody__zizbr {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 560px;
    min-height: 400px;
    margin-bottom: 150px;
}

.Todobody_taskInput__1oWxt {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 270px;
}

.Todobody_taskInput__1oWxt label {
    font-size: 30px;
    font-family: 'Yusei Magic', sans-serif;
    color: #6897ec;
    cursor: pointer;
    text-align: center;
}

.Todobody_taskInput__1oWxt input {
    height: 30px;
    margin-top: 5px;
    border: none;
    outline: none;
    border-bottom: 1px solid #6897ec;
    font-size: 18px;
    transition: 0.5s;
    color: #787575;
}

.Todobody_taskInput__1oWxt input:focus {
    box-shadow: 0 1px #33466d;
}

.Todobody_buttonPlus__1MtBw {
    display: block;
    font-size: 25px;
    position: absolute;
    color: #6897ec;
    top: 70px;
    left: 300px;
    transition: 0.3s;
}

.Todobody_buttonPlus__1MtBw:hover {
    color: #4b68a4;
    cursor: pointer;
}

@media (max-width: 450px) {
    .Todobody_taskInput__1oWxt {
        width: 200px;
    }

    .Todobody_taskInput__1oWxt label {
        font-size: 24px;
    }

    .Todobody_buttonPlus__1MtBw {
        left: 215px;
        top: 60px;
    }
}

.TaskList_TaskList__1LWz9 {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 30px;
}

.TaskList_TaskItem__1VdEH {
    display: block;
    position: relative;
    width: 250px;
    height: 55px;
    word-break: break-all;
    background: linear-gradient(to right, #9ec5f0, #a4c2f7);
    padding: 10px 10px;
    margin: 5px;
    border-radius: 5px;
    cursor: pointer;
    color: #5c5858;
}

.TaskList_TaskItem__1VdEH p {
    margin: 0;
    padding: 0;
}

.TaskList_checked__2XWyH {
    background: lightgreen;
}

.TaskList_checkedIcon__2jZSc {
    display: block;
    font-size: 20px;
    position: absolute;
    left: 242px;
    top: 48px;
}

@media (max-width: 580px) {
    .TaskList_TaskList__1LWz9 {
        width: 300px;
        justify-content: center;
    }
}
.deleteIcon {
    display: block;
    font-size: 20px;
    position: absolute;
    left: 242px;
    top: 6px;
}
@-webkit-keyframes ldio-f8yo9nicvx7-o {
    0%    { opacity: 1; -webkit-transform: translate(0 0); transform: translate(0 0) }
    49.99% { opacity: 1; -webkit-transform: translate(40px,0); transform: translate(40px,0) }
    50%    { opacity: 0; -webkit-transform: translate(40px,0); transform: translate(40px,0) }
    100%    { opacity: 0; -webkit-transform: translate(0,0); transform: translate(0,0) }
}
@keyframes ldio-f8yo9nicvx7-o {
    0%    { opacity: 1; -webkit-transform: translate(0 0); transform: translate(0 0) }
    49.99% { opacity: 1; -webkit-transform: translate(40px,0); transform: translate(40px,0) }
    50%    { opacity: 0; -webkit-transform: translate(40px,0); transform: translate(40px,0) }
    100%    { opacity: 0; -webkit-transform: translate(0,0); transform: translate(0,0) }
}
@-webkit-keyframes ldio-f8yo9nicvx7 {
    0% { -webkit-transform: translate(0,0); transform: translate(0,0) }
    50% { -webkit-transform: translate(40px,0); transform: translate(40px,0) }
    100% { -webkit-transform: translate(0,0); transform: translate(0,0) }
}
@keyframes ldio-f8yo9nicvx7 {
    0% { -webkit-transform: translate(0,0); transform: translate(0,0) }
    50% { -webkit-transform: translate(40px,0); transform: translate(40px,0) }
    100% { -webkit-transform: translate(0,0); transform: translate(0,0) }
}
.ldio-f8yo9nicvx7 div {
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    top: 30px;
    left: 10px;
}
.ldio-f8yo9nicvx7 div:nth-child(1) {
    background: #22f396;
    -webkit-animation: ldio-f8yo9nicvx7 1s linear infinite;
            animation: ldio-f8yo9nicvx7 1s linear infinite;
    -webkit-animation-delay: -0.5s;
            animation-delay: -0.5s;
}
.ldio-f8yo9nicvx7 div:nth-child(2) {
    background: #689cc5;
    -webkit-animation: ldio-f8yo9nicvx7 1s linear infinite;
            animation: ldio-f8yo9nicvx7 1s linear infinite;
    -webkit-animation-delay: 0s;
            animation-delay: 0s;
}
.ldio-f8yo9nicvx7 div:nth-child(3) {
    background: #22f396;
    -webkit-animation: ldio-f8yo9nicvx7-o 1s linear infinite;
            animation: ldio-f8yo9nicvx7-o 1s linear infinite;
    -webkit-animation-delay: -0.5s;
            animation-delay: -0.5s;
}
.loadingio-spinner-dual-ball-ehnduvxrdtd {
    width: 50px;
    height: 50px;
    display: block;
    overflow: hidden;
    background: none;
    position: absolute;
    left: 5px;
    top: 30px;
}
.ldio-f8yo9nicvx7 {
    width: 100%;
    height: 100%;
    position: relative;
    -webkit-transform: translateZ(0) scale(0.5);
            transform: translateZ(0) scale(0.5);
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0; /* see note above */
}
.ldio-f8yo9nicvx7 div { box-sizing: content-box; }
@-webkit-keyframes ldio-yxzmcrti9d-1 {
    0% { top: 32px; height: 136px }
    50% { top: 60px; height: 80px }
    100% { top: 60px; height: 80px }
}
@keyframes ldio-yxzmcrti9d-1 {
    0% { top: 32px; height: 136px }
    50% { top: 60px; height: 80px }
    100% { top: 60px; height: 80px }
}
@-webkit-keyframes ldio-yxzmcrti9d-2 {
    0% { top: 38.99999999999999px; height: 122.00000000000001px }
    50% { top: 60px; height: 80px }
    100% { top: 60px; height: 80px }
}
@keyframes ldio-yxzmcrti9d-2 {
    0% { top: 38.99999999999999px; height: 122.00000000000001px }
    50% { top: 60px; height: 80px }
    100% { top: 60px; height: 80px }
}
@-webkit-keyframes ldio-yxzmcrti9d-3 {
    0% { top: 46px; height: 108px }
    50% { top: 60px; height: 80px }
    100% { top: 60px; height: 80px }
}
@keyframes ldio-yxzmcrti9d-3 {
    0% { top: 46px; height: 108px }
    50% { top: 60px; height: 80px }
    100% { top: 60px; height: 80px }
}
.ldio-yxzmcrti9d div { position: absolute; width: 30px }

.ldio-yxzmcrti9d div:nth-child(1) {
    left: 35px;
    background: #a0f88f;
    -webkit-animation: ldio-yxzmcrti9d-1 0.970873786407767s cubic-bezier(0,0.5,0.5,1) infinite;
            animation: ldio-yxzmcrti9d-1 0.970873786407767s cubic-bezier(0,0.5,0.5,1) infinite;
    -webkit-animation-delay: -0.1941747572815534s;
            animation-delay: -0.1941747572815534s
}

.ldio-yxzmcrti9d div:nth-child(2) {
    left: 85px;
    background: #0e9dec;
    -webkit-animation: ldio-yxzmcrti9d-2 0.970873786407767s cubic-bezier(0,0.5,0.5,1) infinite;
            animation: ldio-yxzmcrti9d-2 0.970873786407767s cubic-bezier(0,0.5,0.5,1) infinite;
    -webkit-animation-delay: -0.0970873786407767s;
            animation-delay: -0.0970873786407767s
}
.ldio-yxzmcrti9d div:nth-child(3) {
    left: 135px;
    background: #ede9de;
    -webkit-animation: ldio-yxzmcrti9d-3 0.970873786407767s cubic-bezier(0,0.5,0.5,1) infinite;
            animation: ldio-yxzmcrti9d-3 0.970873786407767s cubic-bezier(0,0.5,0.5,1) infinite;
    -webkit-animation-delay: undefineds;
            animation-delay: undefineds;
}

.loadingio-spinner-pulse-drd4my4vb84 {
    width: 200px;
    height: 200px;
    display: inline-block;
    overflow: hidden;
    background: #ffffff;
}
.ldio-yxzmcrti9d {
    width: 100%;
    height: 100%;
    position: relative;
    -webkit-transform: translateZ(0) scale(1);
            transform: translateZ(0) scale(1);
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
}
.ldio-yxzmcrti9d div { box-sizing: content-box; }
.AuthForm_AuthForm__2i3Pb {
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: column;
    width: 560px;
    min-height: 400px;
    margin-bottom: 150px;
}

.AuthForm_form__2aW-o p {
    font-size: 30px;
    font-family: 'Yusei Magic', sans-serif;
    color: #6897ec;
    margin-top: 0;
}

.AuthForm_formGroup__24uuQ {
    display: flex;
    flex-direction: column;
}

.AuthForm_input__3ncgd {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
}

label {
    margin-bottom: 10px;
    color: #5c5858;
}

input {
    margin-bottom: 15px;
    border: 1px solid #5c5858;
    height: 20px;
    padding: 8px 10px;
    font-size: 16px;
}

.AuthForm_AuthForm__2i3Pb span {
    padding: 10px;
    background-color: #f8ddda;
    color: #5c5858;
    border-radius: 5px;
    margin-bottom: 10px;
}

.AuthForm_forgotPass__cFTYh {
    text-align: right;
}

.AuthForm_forgotPass__cFTYh span {
    font-size: 15px;
    color: #3b3939;
    margin: 0;
    background-color: #ffffff;
    border-radius: 0;
    font-style: italic;
    cursor: pointer;
    padding: 0 10px 0 0;
}

.AuthForm_forgotPass__cFTYh span:hover {
    text-decoration: underline;
}


@media (max-width: 560px) {
    .AuthForm_AuthForm__2i3Pb {
        width: 460px;
    }
}

@media (max-width: 440px) {
    .AuthForm_AuthForm__2i3Pb {
        width: 380px;
    }

    .AuthForm_passResetMask__1Zwcz {
        width: 100%;
    }
}

@media (max-width: 380px) {
    .AuthForm_form__2aW-o p {
        font-size: 24px;
        text-align: center;
    }

    .AuthForm_form__2aW-o {
        width: 300px;
    }

    .AuthForm_AuthForm__2i3Pb {
        width: 300px;
    }
}
.btn {
    width: 120px;
    height: 30px;
    margin: 0 20px 20px 20px;
    padding: 5px 15px;
    text-transform: uppercase;
    font-weight: 500;
    border: none;
    cursor: pointer;
    color: #5c5858;
}

.btn-login {
    background-color: #a0f88f;
}

.btn-login:hover {
    background-color: #75f874;
}

.btn-register {
    background-color: #a9c9f8;
}

.btn-register:hover {
    background-color: #94b6f8;
}
.ErrorMessage_ErrorMessage__3cX3x {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 280px;
    height: 40px;
    margin: 0 auto;
    border-radius: 20px;
    background-color: #f8ddda;
    -webkit-animation-duration: 1.5s;
            animation-duration: 1.5s;
    -webkit-animation-name: ErrorMessage_changeBg__1yOQh;
            animation-name: ErrorMessage_changeBg__1yOQh;
    -webkit-animation-delay: 300ms;
            animation-delay: 300ms;
}

@-webkit-keyframes ErrorMessage_changeBg__1yOQh {
    from {
        background-color: #f8ddda;
    }

    50% {
        background-color: rgba(255, 0, 0, 0.78);
    }

    to {
        background-color: #f8ddda;
    }
}

@keyframes ErrorMessage_changeBg__1yOQh {
    from {
        background-color: #f8ddda;
    }

    50% {
        background-color: rgba(255, 0, 0, 0.78);
    }

    to {
        background-color: #f8ddda;
    }
}

.ErrorMessage_ErrorMessage__3cX3x p {
    color: #5c5858;
    font-size: 20px;
    font-family: 'Yaldevi', sans-serif;
    font-weight: 400;
    margin: 0;
    padding: 0;
}



.passResetMask {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 80%;
    height: 100%;
    background: #ede9de;
    border-radius: 10px;
}

.passResetMask.entering {
    -webkit-animation: changePass 700ms ease-out forwards;
            animation: changePass 700ms ease-out forwards;
}

.passResetMask.exiting {
    animation: changePass 700ms ease-in alternate-reverse;
}

.passResetMask.exited {
    opacity: 0.4;
    display: none;
}

.passResetMask label {
    width: 280px;
    padding: 0;
    margin-bottom: 20px;
    text-align: center;
    color: #5c5858;
}

.passResetMask input {
    margin-bottom: 15px;
    border: 1px solid #5c5858;
    width: 240px;
    height: 20px;
    padding: 8px 10px;
    font-size: 16px;
}

.passResetMask button {
    width: 120px;
    height: 30px;
    margin: 0 20px 20px 20px;
    padding: 5px 15px;
    text-transform: uppercase;
    font-weight: 500;
    border: none;
    cursor: pointer;
    color: #5c5858;
    background-color: #a0f88f;
}

.passResetMask button:hover {
    background-color: #75f874;
}

.closeButton {cursor: pointer; position: absolute; top: 30px; right: 40px;}
.closeButton:before, .closeButton:after {content: ""; position: absolute; width: 20px; height: 4px; background: #dad5c7;}
.closeButton:before {-webkit-transform: rotate(45deg);transform: rotate(45deg);}
.closeButton:after {-webkit-transform: rotate(-45deg);transform: rotate(-45deg);}

@-webkit-keyframes changePass {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-100px);
                transform: translateX(-100px);
    }

    20% {
        opacity: 1;
    }

    100% {
        -webkit-transform: translateX(0px);
                transform: translateX(0px);
    }
}

@keyframes changePass {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-100px);
                transform: translateX(-100px);
    }

    20% {
        opacity: 1;
    }

    100% {
        -webkit-transform: translateX(0px);
                transform: translateX(0px);
    }
}

@media (max-width: 440px) {
    .passResetMask {
        width: 100%;
    }
}
@-webkit-keyframes ldio-orn7fdbitof { 0% { -webkit-transform: rotate(0); transform: rotate(0) } 100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); } }
@keyframes ldio-orn7fdbitof { 0% { -webkit-transform: rotate(0); transform: rotate(0) } 100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); } }
.ldio-orn7fdbitof > div {
    -webkit-animation: ldio-orn7fdbitof 1s infinite linear;
            animation: ldio-orn7fdbitof 1s infinite linear;
    -webkit-transform-origin: 50px 50px;
            transform-origin: 50px 50px;
}
.ldio-orn7fdbitof > div div { position: absolute; }
.ldio-orn7fdbitof > div div:nth-child(1), .ldio-orn7fdbitof > div div:nth-child(2) {
    width: 82px;
    height: 82px;
    border: 12px solid;
    border-radius: 50%;
    border-color: transparent #a0f88f #a0f88f #a0f88f;
    box-sizing: border-box;
    position: absolute;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    -webkit-transform-origin: 50px 50px;
            transform-origin: 50px 50px;
}
.ldio-orn7fdbitof > div div:nth-child(1) {
    -webkit-transform: rotate(45deg) translate(9px, 9px);
            transform: rotate(45deg) translate(9px, 9px);
}
.ldio-orn7fdbitof > div div:nth-child(2) {
    -webkit-transform: rotate(0deg) translate(9px, 9px);
            transform: rotate(0deg) translate(9px, 9px);;
}
.ldio-orn7fdbitof > div div:nth-child(3) {
    width: 0;
    height: 0;
    border: 8px solid;
    border-color: transparent transparent transparent #a0f88f;
    -webkit-transform: translate(50px, 5px);
            transform: translate(50px, 5px);
}
.loadingio-spinner-reload-tza0xslpnz {
    width: 70px;
    height: 70px;
    display: inline-block;
    overflow: hidden;
    background: none;
}
.ldio-orn7fdbitof {
    width: 100%;
    height: 100%;
    position: relative;
    -webkit-transform: translateZ(0) scale(0.7);
            transform: translateZ(0) scale(0.7);
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0; /* see note above */
}
.ldio-orn7fdbitof div { box-sizing: content-box; }
.Layout_Layout__2BpO9 {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.nav-start {
    display: flex;
    flex-direction: row;
    margin-top: 50px;
    margin-bottom: 30px;
    justify-content: center;
    padding: 0;
}

a {
    list-style-type: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ede9de;
    color: #5c5858;
    height: 50px;
    width: 170px;
    text-decoration: none;
}

a:nth-child(1) {
    border-radius: 15px 0 0 15px;
}

a:nth-child(2) {
    border-radius: 0 15px 15px 0;
}

.nav-start .active {
    background-color: #a0f88f;
}

a:visited {
    color: #5c5858;
}

@media (max-width: 440px) {
    a {
        width: 150px;
    }
}
.info-layout {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 560px;
    min-height: 400px;
    margin-bottom: 150px;
}

.info {
    width: 350px;
    min-height: 300px;
    background-color: rgba(237, 234, 227, 0.5);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
}

.info-text {
    position: relative;
    width: 300px;
    min-height: 100px;
    left: 25px;
    top: 30px;
}

.info-text p {
    color: #787575;
    font-size: 18px;
    text-align: center;
}

.info-text .email {
    color: #6897ec;
    font-size: 22px;
    word-break: break-all;
}

.info button {
    width: 80px;
    height: 30px;
    font-size: 16px;
    border: none;
    background-color: #a0f88f;
    border-radius: 5px;
    position: relative;
    left: 135px;
    top: 100px;
    cursor: pointer;
    color: #666565;
}

.info button:hover {
    background-color: #75f874;
}

@media (max-width: 440px) {
    .info {
        width: 300px;
    }

    .info-text {
        left: 5px;
    }

    .info button {
        left: 110px;
    }
}
