.AuthForm {
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: column;
    width: 560px;
    min-height: 400px;
    margin-bottom: 150px;
}

.form p {
    font-size: 30px;
    font-family: 'Yusei Magic', sans-serif;
    color: #6897ec;
    margin-top: 0;
}

.formGroup {
    display: flex;
    flex-direction: column;
}

.input {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
}

label {
    margin-bottom: 10px;
    color: #5c5858;
}

input {
    margin-bottom: 15px;
    border: 1px solid #5c5858;
    height: 20px;
    padding: 8px 10px;
    font-size: 16px;
}

.AuthForm span {
    padding: 10px;
    background-color: #f8ddda;
    color: #5c5858;
    border-radius: 5px;
    margin-bottom: 10px;
}

.forgotPass {
    text-align: right;
}

.forgotPass span {
    font-size: 15px;
    color: #3b3939;
    margin: 0;
    background-color: #ffffff;
    border-radius: 0;
    font-style: italic;
    cursor: pointer;
    padding: 0 10px 0 0;
}

.forgotPass span:hover {
    text-decoration: underline;
}


@media (max-width: 560px) {
    .AuthForm {
        width: 460px;
    }
}

@media (max-width: 440px) {
    .AuthForm {
        width: 380px;
    }

    .passResetMask {
        width: 100%;
    }
}

@media (max-width: 380px) {
    .form p {
        font-size: 24px;
        text-align: center;
    }

    .form {
        width: 300px;
    }

    .AuthForm {
        width: 300px;
    }
}