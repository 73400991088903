.Todobody {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 560px;
    min-height: 400px;
    margin-bottom: 150px;
}

.taskInput {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 270px;
}

.taskInput label {
    font-size: 30px;
    font-family: 'Yusei Magic', sans-serif;
    color: #6897ec;
    cursor: pointer;
    text-align: center;
}

.taskInput input {
    height: 30px;
    margin-top: 5px;
    border: none;
    outline: none;
    border-bottom: 1px solid #6897ec;
    font-size: 18px;
    transition: 0.5s;
    color: #787575;
}

.taskInput input:focus {
    box-shadow: 0 1px #33466d;
}

.buttonPlus {
    display: block;
    font-size: 25px;
    position: absolute;
    color: #6897ec;
    top: 70px;
    left: 300px;
    transition: 0.3s;
}

.buttonPlus:hover {
    color: #4b68a4;
    cursor: pointer;
}

@media (max-width: 450px) {
    .taskInput {
        width: 200px;
    }

    .taskInput label {
        font-size: 24px;
    }

    .buttonPlus {
        left: 215px;
        top: 60px;
    }
}
