.info-layout {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 560px;
    min-height: 400px;
    margin-bottom: 150px;
}

.info {
    width: 350px;
    min-height: 300px;
    background-color: rgba(237, 234, 227, 0.5);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
}

.info-text {
    position: relative;
    width: 300px;
    min-height: 100px;
    left: 25px;
    top: 30px;
}

.info-text p {
    color: #787575;
    font-size: 18px;
    text-align: center;
}

.info-text .email {
    color: #6897ec;
    font-size: 22px;
    word-break: break-all;
}

.info button {
    width: 80px;
    height: 30px;
    font-size: 16px;
    border: none;
    background-color: #a0f88f;
    border-radius: 5px;
    position: relative;
    left: 135px;
    top: 100px;
    cursor: pointer;
    color: #666565;
}

.info button:hover {
    background-color: #75f874;
}

@media (max-width: 440px) {
    .info {
        width: 300px;
    }

    .info-text {
        left: 5px;
    }

    .info button {
        left: 110px;
    }
}