@keyframes ldio-yxzmcrti9d-1 {
    0% { top: 32px; height: 136px }
    50% { top: 60px; height: 80px }
    100% { top: 60px; height: 80px }
}
@keyframes ldio-yxzmcrti9d-2 {
    0% { top: 38.99999999999999px; height: 122.00000000000001px }
    50% { top: 60px; height: 80px }
    100% { top: 60px; height: 80px }
}
@keyframes ldio-yxzmcrti9d-3 {
    0% { top: 46px; height: 108px }
    50% { top: 60px; height: 80px }
    100% { top: 60px; height: 80px }
}
.ldio-yxzmcrti9d div { position: absolute; width: 30px }

.ldio-yxzmcrti9d div:nth-child(1) {
    left: 35px;
    background: #a0f88f;
    animation: ldio-yxzmcrti9d-1 0.970873786407767s cubic-bezier(0,0.5,0.5,1) infinite;
    animation-delay: -0.1941747572815534s
}

.ldio-yxzmcrti9d div:nth-child(2) {
    left: 85px;
    background: #0e9dec;
    animation: ldio-yxzmcrti9d-2 0.970873786407767s cubic-bezier(0,0.5,0.5,1) infinite;
    animation-delay: -0.0970873786407767s
}
.ldio-yxzmcrti9d div:nth-child(3) {
    left: 135px;
    background: #ede9de;
    animation: ldio-yxzmcrti9d-3 0.970873786407767s cubic-bezier(0,0.5,0.5,1) infinite;
    animation-delay: undefineds;
}

.loadingio-spinner-pulse-drd4my4vb84 {
    width: 200px;
    height: 200px;
    display: inline-block;
    overflow: hidden;
    background: #ffffff;
}
.ldio-yxzmcrti9d {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0;
}
.ldio-yxzmcrti9d div { box-sizing: content-box; }