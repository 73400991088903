.btn {
    width: 120px;
    height: 30px;
    margin: 0 20px 20px 20px;
    padding: 5px 15px;
    text-transform: uppercase;
    font-weight: 500;
    border: none;
    cursor: pointer;
    color: #5c5858;
}

.btn-login {
    background-color: #a0f88f;
}

.btn-login:hover {
    background-color: #75f874;
}

.btn-register {
    background-color: #a9c9f8;
}

.btn-register:hover {
    background-color: #94b6f8;
}