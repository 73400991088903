@import url('https://fonts.googleapis.com/css2?family=Yaldevi:wght@400;500;600&display=swap');

.ErrorMessage {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 280px;
    height: 40px;
    margin: 0 auto;
    border-radius: 20px;
    background-color: #f8ddda;
    animation-duration: 1.5s;
    animation-name: changeBg;
    animation-delay: 300ms;
}

@keyframes changeBg {
    from {
        background-color: #f8ddda;
    }

    50% {
        background-color: rgba(255, 0, 0, 0.78);
    }

    to {
        background-color: #f8ddda;
    }
}

.ErrorMessage p {
    color: #5c5858;
    font-size: 20px;
    font-family: 'Yaldevi', sans-serif;
    font-weight: 400;
    margin: 0;
    padding: 0;
}

