.nav-start {
    display: flex;
    flex-direction: row;
    margin-top: 50px;
    margin-bottom: 30px;
    justify-content: center;
    padding: 0;
}

a {
    list-style-type: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ede9de;
    color: #5c5858;
    height: 50px;
    width: 170px;
    text-decoration: none;
}

a:nth-child(1) {
    border-radius: 15px 0 0 15px;
}

a:nth-child(2) {
    border-radius: 0 15px 15px 0;
}

.nav-start .active {
    background-color: #a0f88f;
}

a:visited {
    color: #5c5858;
}

@media (max-width: 440px) {
    a {
        width: 150px;
    }
}